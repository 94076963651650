/*---------------------PAGINACAO AJAX ---------------------*/
$(function() {

  $('body').on('click', '.PaginacaoNav .first, .PaginacaoNav .prev, .PaginacaoNav .next, .PaginacaoNav .last', function(e) {
    e.preventDefault();
    if ($(this).attr('href') != '#') {
        $.ajax({
            'url': $(this).attr('href'),
            'type': "GET",
            'success': function(data) {
                $('.PaginacaoIndex').html(data).hide().fadeIn('fast');
            }
        });
    } else {
        $(this).addClass('desativa');
        return false;
    }
  });

  $('body').on('click', '.Pagination .first, .Pagination .prev, .Pagination .next, .Pagination .last', function(e) {
    e.preventDefault();
    if ($(this).attr('href') != '#') {
        $.ajax({
            'url': $(this).attr('href'),
            'type': "GET",
            'success': function(data) {
                console.log('data:');
                console.log(data);
                $('.PaginacaoIndex').html(data).hide().fadeIn('fast').scrollTop(300);
                $('html, body').animate({ scrollTop: 0 }, 'slow');
            }
        });
    } else {
        $(this).addClass('desativa');
        return false;
    }
  });

  $('body').on('click', '.PaginationComment .first, .PaginationComment .prev, .PaginationComment .next, .PaginationComment .last', function(e) {
      e.preventDefault();
      if ($(this).attr('href') != '#') {
          $.ajax({
              'url': $(this).attr('href'),
              'type': "GET",
              'success': function(data) {
                  $('.comments').html(data).hide().fadeIn('fast');
              }
          });
      } else {
          $(this).addClass('desativa');
          return false;
      }
  });
});