function sendData(url, form_name){
  return $.post(url, $(form_name).serialize(), function(data){
    return data;
  });
}

function randomString(length) {
  return Math.round((Math.pow(36, length + 1) - Math.random() * Math.pow(36, length))).toString(36).slice(1);
}

function getCaptchaId(url){
  return $.get(url, function( data ) {
    return data;
  });
}

function resetModalToDefault(){
  $('#texto-digite-captcha').text('Digite o código para confirmar o envio:');
  $('#captcha-error-msg').css('display','none');
  $('#captcha-success-msg').css('display','none');
}

function getModal(){
  //Set heigth and width to mask to fill up the whole screen
  var mask = '#mask';
  var close = '#close';
  var maskHeight = $(document).height();
  var maskWidth = $(window).width();
  $(mask).css('display', 'block');
  $(mask).css({'width':maskWidth,'height':maskHeight});
  $(close).unbind('click');
  $(close).on('click', function(e){
    e.preventDefault();
    $(mask).css('display', 'none');
  });
}

function getCaptcha(form_name, url) {

  resetModalToDefault();

  getModal();

  getCaptchaId("/apis/captcha_api.php").done(function(data) {
    var response = JSON.parse(data);
    var captchaId = response.id;
    var imagePath =  "/extensions/securimage/captcha_show.php?captchaId=";
    var imgCaptchaSrc = imagePath + captchaId;
    var captchaInput = '#captchaId';
    var form = '#captcha-form';
    var imageInput = '#siimage';
    var errorContainer = '#captcha-error-msg';
    var successContainer = '#captcha-success-msg';
    var textInput = '#texto-digite-captcha';
    var formModal = '#div-form-enviar-materia';
    var modal = '#div-form-comentario-raiz';
    var errorMessage = 'Desculpe-nos, ocorreu um erro, por favor, tente novamente. <br />';
    var warningMessage = 'Essa janela será fechada em poucos segundos.. <br />';
    var mask = '#mask';

    $(form).children(captchaInput).val(captchaId);
    $(form).children(imageInput).attr('src', imgCaptchaSrc);
    $(form).unbind('submit');
    $(form).on('submit', function(e){
      e.preventDefault();
      $.post('/apis/captcha_api.php', $(form).serialize(), function(data){
        var response = JSON.parse(data);
        if(response.success == 'false'){
          var newCaptchaId = response.id;
          $(form).children(captchaInput).val(newCaptchaId);
          $(form).children(imageInput).attr('src', imagePath + newCaptchaId);
          $(errorContainer).css('display','block');

        } else if (response.success == 'true') {


          $(errorContainer).css('display','none');
          $(successContainer).css('display','block');
          sendData(url,form_name).done(function(data){
            data = JSON.parse(data);
            if (data.success == 'true') {
              $(textInput).html(warningMessage);
              $(successContainer).html(data.msg);
            } else if (data.success == 'false') {
              $(textInput).html(errorMessage);
              $(errorContainer).html(data.msg);
            }
            setTimeout(function() {
              $(formModal).css('display','none');
              $(modal).fadeOut("slow").css("display","none");
              $(mask).css('display', 'none');
              $(form_name).each(
                function() {
                  this.reset();
                });
            }, 9000);
          });
        }
      });
    });
    //transition effect
    $(mask).css('background-color', 'rgba(0, 0, 0, 0.5)');
    return false;
  });
}