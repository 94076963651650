$(function() {

  $('#comentario').submit(function(e){

    var nome = '#nome_comment';
    var email = '#email_comment';
    var texto = '#texto_comment';

    if ($(nome).val() == '') {alert("Insira o nome"); $(nome).focus(); return false;}
    if ($(email).val() == '') {alert("Insira o email"); $(email).focus(); return false;}
    if ($(texto).val() == '') {alert("Insira um comentario"); $(texto).focus(); return false;}

    getCaptcha('#comentario', '/comment.php');

    return false;
  });

  $('body').on('click', '.cmt-navigation .first, .cmt-navigation .prev, .cmt-navigation .next, .cmt-navigation .last', function(e) {
      e.preventDefault();
      //$(this).addClass('active');
      var self = this;
      if ($(this).attr('href') != '#') {
          //$('#commentarios').animate({'opacity': 1}, 200);
          $.ajax({
              'url': $(this).attr('href'),
              'type': "GET",
              'success': function(data) {
                  $('.cmt-navigation').remove();
                  $('.CommentUl').append(data).fadeIn('fast');
              }
          });
      } else {
          $(this).addClass('desativa');
          return false;
      }
  });

});

function commentCapa(cids) {
  $('#cmt').remove();
  $.ajax({
    'url': '/comment_count.php?cids=' + cids,
    'type': "GET",
    'success': function(data) {
      $('#cmt').remove();
      var explodedData = data.split(',');
      explodedData.forEach(function(cidTot) {
        var explodeTotal = cidTot.split('|');
        var cmtId = '#cmt' + explodeTotal[0];
        if (explodeTotal[1] > 2) {
            console.log($(cmtId).html(explodeTotal[1]));
            $(cmtId).html(explodeTotal[1]);
            $(cmtId).attr('title', "Comentarios");
            $(cmtId).attr('style', 'cursor:pointer');
            $(cmtId).attr('onclick', 'window.open("' + decodeURIComponent(explodeTotal[2]) + '#comentario", "_self");');
        } else {
            $(cmtId).remove();
        }
      });
    }
  });
}